import React from 'react'
import { Link } from 'gatsby'

function List(props) {
  const items = props.data.map(item => (
    <li className="list-section__item" key={item.id}>
      {item.isLink ? (
        <Link to={item.path} className={'footer__contact-link text--5 ' + (props.isDarkLink ? 'footer__contact-link--dark' : '')}>
          {item.text}
        </Link>
      ) : (
        <p className="list-section__paragraph text--5">{item.text}</p>
      )}
    </li>
  ))

  return (
    <section
      className={
        'list-section ' + (props.additionalClass ? props.additionalClass : '')
      }
    >
      <div className="container">
        <div className="list-section__inner">
          <ol className="list-section__list">{items}</ol>
        </div>
      </div>
    </section>
  )
}

export default List
